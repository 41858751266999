import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Input } from 'antd'
import { ImportOutlined } from '@ant-design/icons'

import Button from 'Components/Button'
import { InputSubmitWrapper } from './styled'

const InputSubmit = props => {
  const { intl, isLoading, onSubmit } = props
  const [orderNumber, setOrderNumber] = useState()
  return (
    <InputSubmitWrapper>
      <Input
        placeholder={intl.formatMessage({ id: 'home.placeholder.import' })}
        onPressEnter={() => onSubmit(orderNumber)}
        onChange={e => setOrderNumber(e.target.value)}
      />
      <Button
        icon={<ImportOutlined />}
        loading={isLoading}
        onClick={() => onSubmit(orderNumber)}>
        <span>
          <FormattedMessage id={'home.button.import'} />
        </span>
      </Button>
    </InputSubmitWrapper>
  )
}
InputSubmit.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
}
export default injectIntl(InputSubmit)
