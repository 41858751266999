// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'
export const GET_TEAMMATES = 'GET_TEAMMATES'

// APP
export const GET_STORES = 'GET_STORES'
export const GET_GDRIVE_CONFIG = 'GET_GDRIVE_CONFIG'

// ORDER
export const IMPORT_ORDER = 'IMPORT_ORDER'
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_LOGS = 'GET_ORDER_LOGS'
export const GET_ORDER_STEPPER = 'GET_ORDER_STEPPER'
export const MOVE_ORDERS = 'MOVE_ORDERS'
export const DELETE_ORDER = 'DELETE_ORDER'
export const UPLOAD_ATTACHMENTS = 'UPLOAD_ATTACHMENTS'
export const ADD_NOTES = 'ADD_NOTES'
export const SEND_ORDER_TO_CUSTOMER = 'SEND_ORDER_TO_CUSTOMER'
export const MANUAL_SEND_ORDER_TO_CUSTOMER = 'MANUAL_SEND_ORDER_TO_CUSTOMER'
export const VERIFY_OTP = 'VERIFY_OTP'
export const SEND_CONDITIONS_COPY_TO_CUSTOMER =
  'SEND_CONDITIONS_COPY_TO_CUSTOMER'
export const MANUAL_SEND_CONDITIONS_COPY_TO_CUSTOMER =
  'MANUAL_SEND_CONDITIONS_COPY_TO_CUSTOMER'
export const GET_ORDERS = 'GET_ORDERS'
