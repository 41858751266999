import React, { useEffect, useRef } from "react";
import axios from "axios";
import useGoogleDrivePicker from "src/google-drive-picker";

import { getCookie, setCookie } from "Common/cookies";
import Button from "Components/Button";
import propType from "prop-types";

const GDrivePicker = (props) => {
  const authToken = useRef();

  const [openPicker, authRes] = useGoogleDrivePicker();

  useEffect(() => {
    if (getCookie("googleAuthToken")) {
      authToken.current = getCookie("googleAuthToken");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    if (authRes) {
      authToken.current = authRes.access_token;
      setCookie("googleAuthToken", authRes.access_token, {expires: new Date(Date.now()+(authRes.expires_in*1000))});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRes]);

  const handlePickerOpen = () => {
    openPicker({
      clientId: props.gDriveConfig.clientId,
      developerKey: props.gDriveConfig.apiKey,
      token: authToken.current,
      showUploadView: false,
      supportDrives: true,
      multiselect: false,
      viewMimeTypes: "application/pdf",
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else if (data.docs && data.docs.length > 0) {
          const file = await retrieveFile(data.docs[0]);
          props.onUploadFile(file);
        }
      }
    });
  };

  const retrieveFile = async (doc) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`, {
        headers: {
          Authorization: `Bearer ${authToken.current}`,
        },
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      return new File([blob], doc.name, {
        type: blob.type ?? 'application/pdf',
      });
    } catch (error) {
      console.error('Error fetching blob:', error);
      throw error;
    }
  };

  return (
    <Button onClick={handlePickerOpen}>
      {props.buttonText}
    </Button>
  );
};

GDrivePicker.propTypes = {
  onUploadFile: propType.func.isRequired,
  buttonText: propType.string.isRequired,
  gDriveConfig: propType.object.isRequired,
};

export default GDrivePicker;