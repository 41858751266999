import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getOrders, deleteOrder } from 'Redux/actions/order-actions'

import OrdersTable from 'Components/OrdersTable'
import { PageTitle } from 'Common/styled'

class Archive extends Component {
  static propTypes = {
    deleteOrder: PropTypes.func,
    getOrders: PropTypes.func,
    orders: PropTypes.array,
    storeId: PropTypes.number
  }

  state = {
    isLoading: false
  }

  componentDidMount() {
    this.getOrders()
  }
  componentDidUpdate(prevProps, prevState) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getOrders()
  }

  getOrders = async () => {
    const { getOrders } = this.props
    this.setState({ isLoading: true })
    await getOrders('archive')
    this.setState({ isLoading: false })
  }

  deleteOrder = async orderId => {
    const { deleteOrder } = this.props
    this.setState({ isLoading: true })

    await deleteOrder(orderId)
    this.getOrders()
  }

  render() {
    const { isLoading } = this.state
    const { orders } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'archive.title'} />
        </PageTitle>
        <OrdersTable
          isLoading={isLoading}
          data={orders}
          deleteOrder={this.deleteOrder}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.current.storeId,
  orders: state.order.list
})
const mapDispatchToProps = {
  getOrders,
  deleteOrder
}
export default connect(mapStateToProps, mapDispatchToProps)(Archive)
