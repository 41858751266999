const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-ecosign-itlm-ping-6hjthguuma-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080',
  },
  test: {
    LOGIN_URL: `https://test-lmit-ecosign-itlm-ping-6hjthguuma-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://ecosignbe-test.cloud.leroymerlin.it',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-ecosign-itlm-ping-6hjthguuma-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://ecosignbe.cloud.leroymerlin.it',
  },
}

export default envConfig[env]
