import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Select } from 'antd'
import { connect } from 'react-redux'

import {
  getOrders,
  importOrder,
  moveOrders,
  deleteOrder
} from 'Redux/actions/order-actions'
import { getTeammates } from 'Redux/actions/user-actions'

import InputSubmit from 'Components/InputSubmit'
import OrdersTable from 'Components/OrdersTable'
import { PageTitle } from 'Common/styled'

import { isMobile } from 'Common/constants'

class Home extends Component {
  static propTypes = {
    getOrders: PropTypes.func,
    getTeammates: PropTypes.func,
    deleteOrder: PropTypes.func,
    importOrder: PropTypes.func,
    moveOrders: PropTypes.func,
    orders: PropTypes.array,
    storeId: PropTypes.number,
    user: PropTypes.object
  }

  state = {
    isLoading: false,
    selectedOrderIds: []
  }

  componentDidMount() {
    const { getTeammates } = this.props
    this.getOrders()
    getTeammates()
  }
  componentDidUpdate(prevProps, prevState) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getOrders()
  }

  getOrders = async () => {
    const { getOrders } = this.props
    this.setState({ isLoading: true })
    await getOrders()
    this.setState({ isLoading: false })
  }

  importOrder = async orderNumber => {
    const { history, user, storeId, importOrder } = this.props
    if (orderNumber) {
      this.setState({ isLoading: true })

      const res = await importOrder(orderNumber, storeId, true, true)
      if (res?.status === 200) return history.push(`/order/${res.orderNumber}`)
      if (res?.status === 202)
        Modal.confirm({
          title: res.message,
          onOk: () => {
            this.setState(
              { selectedOrderIds: [res.orderId], selectedTeammate: user.ldap },
              () => this.moveOrders()
            )
          }
        })

      this.setState({ isLoading: false })
    }
  }

  deleteOrder = async orderId => {
    const { deleteOrder } = this.props
    this.setState({ isLoading: true })

    await deleteOrder(orderId)
    this.getOrders()
  }

  moveOrders = async () => {
    const { selectedOrderIds, selectedTeammate } = this.state
    const { moveOrders } = this.props
    this.setState({ isLoading: true })

    await moveOrders(selectedOrderIds, selectedTeammate)
    this.setState({ selectedOrderIds: [], selectedTeammate: undefined })
    this.getOrders()
  }

  openTeammatesModal = () => {
    const { intl } = this.props
    Modal.confirm({
      title: intl.formatMessage({ id: 'home.teammateModal.title' }),
      content: this.renderTeammatesSelect(),
      okText: intl.formatMessage({ id: 'home.teammateModal.okText' }),
      cancelText: intl.formatMessage({ id: 'home.teammateModal.cancelText' }),
      onOk: this.moveOrders,
      onCancel: () => this.setState({ selectedTeammate: undefined })
    })
  }

  renderTeammatesSelect() {
    const { intl, teammates } = this.props
    return (
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder={intl.formatMessage({ id: 'home.placeholder.teammate' })}
        onChange={value => this.setState({ selectedTeammate: value })}>
        {teammates.map(t => (
          <Select.Option key={t.id}>{t.displayName}</Select.Option>
        ))}
      </Select>
    )
  }

  render() {
    const { isLoading, selectedOrderIds } = this.state
    const { orders } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
        <InputSubmit isLoading={isLoading} onSubmit={this.importOrder} />
        {!isMobile ? (
          <OrdersTable
            isLoading={isLoading}
            data={orders}
            rowSelection={{
              selectedRowKeys: selectedOrderIds,
              onChange: keys => this.setState({ selectedOrderIds: keys })
            }}
            openTeammatesModal={this.openTeammatesModal}
            deleteOrder={this.deleteOrder}
          />
        ) : null}
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.current,
  storeId: state.user.current.storeId,
  teammates: state.user.teammates,
  orders: state.order.list
})
const mapDispatchToProps = {
  importOrder,
  moveOrders,
  deleteOrder,
  getOrders,
  getTeammates
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
