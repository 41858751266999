import {
  GET_ORDER,
  GET_ORDER_LOGS,
  GET_ORDER_STEPPER,
  GET_ORDERS,
} from '../action-types'

const initialState = {
  list: [],
  current: {},
  logs: [],
  stepper: {},
}

const order = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER:
      return { ...state, current: payload }
    case GET_ORDER_LOGS:
      return { ...state, logs: payload }
    case GET_ORDERS:
      return { ...state, list: payload }
    case GET_ORDER_STEPPER:
      return { ...state, stepper: payload }
    default:
      return state
  }
}

export default order;