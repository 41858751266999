import { GET_STORES, GET_GDRIVE_CONFIG } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getGDriveConfig = () => async dispatch => {
  try {
    const res = await api.config.gdriveconfig()
    return dispatch({ type: GET_GDRIVE_CONFIG, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}