import { useEffect, useState } from 'react';

import useInjectScript from './useInjectScript';

const defaultConfiguration = {
  clientId: '',
  developerKey: '',
  viewId: 'DOCS',
  callbackFunction: () => null,
};

const language = navigator.language.split(/[-_]/)[0] ?? 'it';

const useGoogleDrivePicker = (props) => {
  const [authRes, setAuthRes] = useState();
  const [config, setConfig] = useState(defaultConfiguration);
  const [openAfterAuth, setOpenAfterAuth] = useState(false);
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);

  const [loaded, error] = useInjectScript('https://apis.google.com/js/api.js');
  const [loadedGsi, errorGsi] = useInjectScript('https://accounts.google.com/gsi/client');

  const defaultScopes = ['https://www.googleapis.com/auth/drive.readonly'];

  let picker;

  // get the apis from googleapis
  useEffect(() => {
    if (loaded && !error && loadedGsi && !errorGsi && !pickerApiLoaded) {
      loadApis();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error, loadedGsi, errorGsi, pickerApiLoaded]);

  // use effect to open picker after auth
  useEffect(() => {
    if (
      openAfterAuth &&
      config.token &&
      loaded &&
      !error &&
      loadedGsi &&
      !errorGsi &&
      pickerApiLoaded
    ) {
      createPicker(config);
      setOpenAfterAuth(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAfterAuth, config.token, loaded, error, loadedGsi, errorGsi, pickerApiLoaded, config]);

  // open the picker
  const openPicker = (config) => {
    // global scope given configuration
    setConfig(config);

    // if we didnt get token generate token
    if (!config.token) {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        scope: (config.customScopes
          ? [...defaultScopes, ...config.customScopes]
          : defaultScopes
        ).join(' '),
        callback: (tokenResponse) => {
          setAuthRes(tokenResponse);
          createPicker({ ...config, token: tokenResponse.access_token });
        },
      });
      client.requestAccessToken();
    }

    // if we have token and everything is loaded open the picker
    if (config.token && loaded && !error && pickerApiLoaded) {
      return createPicker(config);
    }
  };

  // load the Drive picker api
  const loadApis = () => {
    window.gapi.load('auth');
    window.gapi.load('picker', { callback: onPickerApiLoad });
  };

  const onPickerApiLoad = () => {
    setPickerApiLoaded(true);
  };

  const createPicker = ({
    token,
    appId = '',
    developerKey,
    disabled,
    multiselect,
    setOrigin,
    viewMimeTypes,
    locale = language,
    setIncludeFolders,
    setSelectFolderEnabled,
    callbackFunction,
  }) => {
    if (disabled) return false;

    const classicView = new window.google.picker.DocsView().setParent('root').setIncludeFolders(true);
    if (viewMimeTypes) classicView.setMimeTypes(viewMimeTypes);
    if (setIncludeFolders) classicView.setIncludeFolders(setIncludeFolders);
    if (setSelectFolderEnabled) classicView.setSelectFolderEnabled(setSelectFolderEnabled);

    const sharedDrivesView = new window.google.picker.DocsView()
      .setOwnedByMe(false)
      .setEnableDrives(true)
      .setIncludeFolders(true);
    if (viewMimeTypes) sharedDrivesView.setMimeTypes(viewMimeTypes);

    const sharedWithMeView = new window.google.picker.DocsView()
      .setOwnedByMe(false)
      .setIncludeFolders(true)
      .setLabel('Shared with Me');
    if (viewMimeTypes) sharedWithMeView.setMimeTypes(viewMimeTypes);

    picker = new window.google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token)
      .setDeveloperKey(developerKey)
      .setLocale(locale)
      .setCallback(callbackFunction)
      .addView(classicView)
      .addView(sharedDrivesView)
      .addView(sharedWithMeView);
    
    if (setOrigin) {
      picker.setOrigin(setOrigin);
    }

    if (multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
    }

    picker.build().setVisible(true);
    
    return true;
  }

  return [openPicker, authRes];
};

export default useGoogleDrivePicker;