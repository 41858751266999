import styled from 'styled-components'
import { Avatar, Steps as AntdSteps } from 'antd'

export const Steps = styled(AntdSteps)`
  margin: 16px 0;
`
export const Step = styled(AntdSteps.Step)`
  & .ant-steps-item-tail {
    margin-left: 48px;
    @media (max-width: 600px) {
      margin-left: 18px;
    }
  }
  & .ant-steps-item-icon {
    margin-left: 32px;
    @media (max-width: 600px) {
      margin-left: 0;
      margin-right: 0 !important;
    }
  }
  & .ant-steps-item-content {
    width: 96px;
    @media (max-width: 600px) {
      display: none !important;
    }
    & .ant-steps-item-title {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1.2;
    }
  }
`
export const StepIconWrapper = styled(Avatar)`
  background-color: ${props => props.color};
`
export const StatusDescription = styled.p`
  text-align: center;
`
