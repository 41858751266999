import API from './axiosConfig'
import { BU } from './constants'

const api = {
  user: {
    authenticate: token =>
      API.get('/user', { headers: { Authorization: `Bearer ${token}` }, params: { bu: BU } }),
    teammates: () => API.get('/teammates', { params: { bu: BU } })
  },
  store: {
    list: () => API.get('/stores', { params: { bu: BU } })
  },
  config: {
    gdriveconfig: () => API.get('/gdriveconfig', { params: { bu: BU } }),
  },
  order: {
    list: (storeId, type) =>
      API.get('/orders', { params: { bu: BU, storeId, type } }),
    import: (orderNumber, storeId, log, pdf) =>
      API.get(`/order/${orderNumber}/import`, {
        params: { bu: BU, storeId, log, pdf }
      }),
    get: (orderNumber, storeId) =>
      API.get(`/order/${orderNumber}`, { params: { bu: BU, storeId } }),
    logs: (orderNumber, storeId) =>
      API.get(`/order/${orderNumber}/logs`, { params: { bu: BU, storeId } }),
    stepper: (orderNumber, storeId) =>
      API.get(`/order/${orderNumber}/stepper`, { params: { bu: BU, storeId } }),
    delete: (orderId, storeId) =>
      API.delete(`/order/${orderId}`, { params: { bu: BU, storeId } }),
    uploadAttachments: (orderId, body) =>
      API.post(`/order/${orderId}/uploadAttachments`, body, {
        params: { bu: BU },
        headers: { 'Content-Type': 'multipart/form-data; charset=utf-8' }
      }),
    addNotes: (orderId, body) =>
      API.post(`/order/${orderId}/addNotes`, body, { params: { bu: BU } }),
    sendToCustomer: (orderId, body) =>
      API.post(`/order/${orderId}/sendOrderToCustomer`, body, {
        params: { bu: BU }
      }),
    manualSendToCustomer: orderId =>
      API.post(
        `/order/${orderId}/manualSendOrderToCustomer`,
        {},
        { params: { bu: BU } }
      ),
    verifyOtp: (orderId, otp) =>
      API.post(`/order/${orderId}/verifyOtp`, { otp }, { params: { bu: BU } }),
    sendConditionsCopyToCustomer: (orderId, body) =>
      API.post(`/order/${orderId}/sendConditionsCopyToCustomer`, body, {
        params: { bu: BU }
      }),
    manualSendConditionsCopyToCustomer: orderId =>
      API.post(
        `/order/${orderId}/manualSendConditionsCopyToCustomer`,
        {},
        { params: { bu: BU } }
      ),
    move: body =>
      API.post('/order/updateInChargeOf', body, { params: { bu: BU } })
  }
}

export default api;