import { GET_STORES, GET_GDRIVE_CONFIG } from '../action-types'

export const initialState = {
  stores: [],
  gDriveConfig: {},
}

const app = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORES:
      return { ...state, stores: payload }
    case GET_GDRIVE_CONFIG:
      return { ...state, gDriveConfig: payload }
    default:
      return state
  }
}

export default app;
