import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import { Result } from 'antd'
import { connect } from 'react-redux'

import { getStores } from 'Redux/actions/app-actions'
import { updateStoreId } from 'Redux/actions/user-actions'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import Archive from 'Containers/Archive'
import Order from 'Containers/Order'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import StoreModal from 'Components/StoreModal'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    getStores: PropTypes.func,
    stores: PropTypes.array,
    updateStoreId: PropTypes.func,
    user: PropTypes.object,
  }
  state = { isStoreModalVisible: false }

  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: '276d2fdf-6979-4458-bab4-465569394fed', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, getStores } = this.props
    if (isEmpty(prevProps.user) && user.isAdmin) {
      getStores()
      this.openStoreModal()
    }
  }

  updateStoreId = storeId => {
    const { updateStoreId } = this.props
    updateStoreId(storeId)
    this.closeStoreModal()
  }

  openStoreModal = () => this.setState({ isStoreModalVisible: true })
  closeStoreModal = () => this.setState({ isStoreModalVisible: false })

  renderUserIsNotEnabled() {
    const { intl } = this.props
    return (
      <Result
        status={'403'}
        title={intl.formatMessage({ id: 'app.user.isNotEnabled' })}
      />
    )
  }

  render() {
    const { isStoreModalVisible } = this.state
    const { user, stores } = this.props
    return (
      <WhiteLayout>
        <Header user={user} changeStore={this.openStoreModal} />
        <Auth>
          <PaddedContent>
            <Switch>
              {!user.isEnabled ? (
                <Route render={() => this.renderUserIsNotEnabled()} />
              ) : null}
              <Route component={Home} exact path={'/'} />
              <Route component={Archive} exact path={'/archive'} />
              <Route component={Order} path={'/order/:orderNumber'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <StoreModal
          stores={stores}
          isVisible={isStoreModalVisible}
          onCloseModal={this.closeStoreModal}
          onSelectStore={this.updateStoreId}
        />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user.current,
  stores: state.app.stores,
})
const mapDispatchToProps = {
  getStores,
  updateStoreId,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App))
