import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
const CustomMenu = props => {
  const location = useLocation()
  return (
    <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '57px' }}>
      <Menu.Item key={'/'}>
        <Link to={'/'}>
          <FormattedMessage id={'app.menuItem.home'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/archive'}>
        <Link to={'/archive'}>
          <FormattedMessage id={'app.menuItem.archive'} />
        </Link>
      </Menu.Item>
    </Menu>
  )
}
export default CustomMenu
