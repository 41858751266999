import {
  GET_ORDER,
  GET_ORDER_LOGS,
  GET_ORDER_STEPPER,
  GET_ORDERS,
  IMPORT_ORDER,
  MOVE_ORDERS,
  DELETE_ORDER,
  UPLOAD_ATTACHMENTS,
  ADD_NOTES,
  SEND_ORDER_TO_CUSTOMER,
  MANUAL_SEND_ORDER_TO_CUSTOMER,
  VERIFY_OTP,
  SEND_CONDITIONS_COPY_TO_CUSTOMER,
  MANUAL_SEND_CONDITIONS_COPY_TO_CUSTOMER
} from 'Redux/action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const importOrder =
  (orderNumber, storeId, log, pdf) => async dispatch => {
    try {
      const res = await api.order.import(orderNumber, storeId, log, pdf)
      const { status, data } = res
      dispatch({ type: IMPORT_ORDER })
      return {
        status,
        message: data.message,
        orderId: data.orderId,
        orderNumber: data.orderNumber
      }
    } catch (error) {
      return handleServerError(error)
    }
  }

export const getOrder = orderNumber => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.order.get(orderNumber, storeId)
    const { status, data } = res
    dispatch({ type: GET_ORDER, payload: data || {} })
    return {
      status,
      orderNumber: data.orderNumber,
      storeId: data.storeId
    }
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrderLogs = orderNumber => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.order.logs(orderNumber, storeId)
    return dispatch({ type: GET_ORDER_LOGS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrderStepper = orderNumber => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.order.stepper(orderNumber, storeId)
    return dispatch({ type: GET_ORDER_STEPPER, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const moveOrders = (orderIds, newInChargeOf) => async dispatch => {
  try {
    await api.order.move({ orderIds, newInChargeOf })
    return dispatch({ type: MOVE_ORDERS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteOrder = orderId => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    await api.order.delete(orderId, storeId)
    return dispatch({ type: DELETE_ORDER })
  } catch (error) {
    return handleServerError(error)
  }
}

export const uploadAttachments = (orderId, attachments) => async dispatch => {
  const body = new FormData()
  attachments.forEach(file => body.append('attachments[]', file))

  try {
    await api.order.uploadAttachments(orderId, body)
    return dispatch({ type: UPLOAD_ATTACHMENTS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const addNotes = (orderId, notes) => async dispatch => {
  const body = { notes }
  try {
    await api.order.addNotes(orderId, body)
    return dispatch({ type: ADD_NOTES })
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendOrderToCustomer = (orderId, recipient) => async dispatch => {
  const body = { recipient }
  try {
    await api.order.sendToCustomer(orderId, body)
    return dispatch({ type: SEND_ORDER_TO_CUSTOMER })
  } catch (error) {
    return handleServerError(error)
  }
}

export const manualSendOrderToCustomer = orderId => async dispatch => {
  try {
    await api.order.manualSendToCustomer(orderId)
    return dispatch({ type: MANUAL_SEND_ORDER_TO_CUSTOMER })
  } catch (error) {
    return handleServerError(error)
  }
}

export const verifyOtp = (orderId, otp) => async dispatch => {
  try {
    await api.order.verifyOtp(orderId, otp)
    return dispatch({ type: VERIFY_OTP })
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendConditionsCopyToCustomer =
  (orderId, customerName, customerEmail) => async dispatch => {
    const body = { customerName, customerEmail }
    try {
      await api.order.sendConditionsCopyToCustomer(orderId, body)
      return dispatch({ type: SEND_CONDITIONS_COPY_TO_CUSTOMER })
    } catch (error) {
      return handleServerError(error)
    }
  }

export const manualSendConditionsCopyToCustomer = orderId => async dispatch => {
  try {
    await api.order.manualSendConditionsCopyToCustomer(orderId)
    return dispatch({ type: MANUAL_SEND_CONDITIONS_COPY_TO_CUSTOMER })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrders = type => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.order.list(storeId, type)
    return dispatch({ type: GET_ORDERS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
