import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Badge, Empty, Popconfirm, Space } from 'antd'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'

import Button from 'Components/Button'
import { StyledTable } from './styled'

import { formatDate, getShortName } from 'Common/utils'
import { DATE_TIME_FORMAT, ecoSignStatusesMap } from 'Common/constants'

const OrdersTable = props => {
  const {
    intl,
    isLoading,
    data,
    rowSelection,
    openTeammatesModal,
    deleteOrder
  } = props
  const columns = [
    {
      key: 'orderNumber',
      title: intl.formatMessage({ id: 'order.table.column.orderNumber' }),
      dataIndex: 'fullOrderNumber',
      sorter: (a, b) => a.fullOrderNumber.localeCompare(b.fullOrderNumber)
    },
    {
      key: 'totalAmount',
      title: intl.formatMessage({ id: 'order.table.column.totalAmount' }),
      dataIndex: 'totalAmount',
      render: totalAmount => `${totalAmount}€`,
      sorter: (a, b) => a.totalAmount - b.totalAmount
    },
    {
      key: 'createdBy',
      title: intl.formatMessage({ id: 'order.table.column.createdBy' }),
      render: (_, row) =>
        row.createdByFirstName && row.createdByLastName
          ? getShortName(row.createdByFirstName, row.createdByLastName)
          : row.createdByLDAP,
      sorter: (a, b) =>
        getShortName(a.createdByFirstName, a.createdByLastName).localeCompare(
          getShortName(b.createdByFirstName, b.createdByLastName)
        )
    },
    {
      key: 'inChargeOf',
      title: intl.formatMessage({ id: 'order.table.column.inChargeOf' }),
      render: (_, row) =>
        getShortName(row.inChargeOfFirstName, row.inChargeOfLastName),
      sorter: (a, b) =>
        getShortName(a.inChargeOfFirstName, a.inChargeOfLastName).localeCompare(
          getShortName(b.inChargeOfFirstName, b.inChargeOfLastName)
        )
    },
    {
      key: 'orderDate',
      title: intl.formatMessage({ id: 'order.table.column.orderDate' }),
      dataIndex: 'orderDate',
      render: orderDate => formatDate(orderDate, DATE_TIME_FORMAT),
      sorter: (a, b) => a.orderDate.localeCompare(b.orderDate)
    },
    {
      key: 'ecoSignStatus',
      title: intl.formatMessage({ id: 'order.table.column.ecoSignStatus' }),
      dataIndex: 'ecoSignStatus',
      render: ecoSignStatus => (
        <>
          <Badge status={ecoSignStatusesMap[ecoSignStatus]} />{' '}
          <FormattedMessage id={`ecoSignStatus.${ecoSignStatus}`} />
        </>
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `ecoSignStatus.${a.ecoSignStatus}` })
          .localeCompare(
            intl.formatMessage({ id: `ecoSignStatus.${b.ecoSignStatus}` })
          )
    },
    {
      key: 'orderStatus',
      title: intl.formatMessage({ id: 'order.table.column.orderStatus' }),
      dataIndex: 'orderStatus',
      render: orderStatus => (
        <FormattedMessage id={`orderStatus.${orderStatus}`} />
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `orderStatus.${a.orderStatus}` })
          .localeCompare(
            intl.formatMessage({ id: `orderStatus.${b.orderStatus}` })
          )
    },
    {
      key: 'updatedAt',
      title: intl.formatMessage({ id: 'order.table.column.updatedAt' }),
      dataIndex: 'updatedAt',
      render: updatedAt => formatDate(updatedAt, DATE_TIME_FORMAT),
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    },
    {
      key: 'action',
      width: 48,
      render: (_, row) => (
        <Space>
          <Link to={`/order/${row.orderNumber}`}>
            <Button icon={<EyeOutlined />} />
          </Link>
          <Popconfirm
            placement={'topRight'}
            title={intl.formatMessage({ id: 'order.delete.message' })}
            okText={intl.formatMessage({ id: 'order.delete.okText' })}
            cancelText={intl.formatMessage({ id: 'order.delete.cancelText' })}
            onConfirm={() => deleteOrder(row.Id)}>
            <Button icon={<DeleteOutlined />} type={'danger'} ghost />
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      rowKey={row => row.Id}
      rowSelection={rowSelection}
      pagination={{
        showTotal: () =>
          rowSelection ? (
            <Button
              disabled={rowSelection.selectedRowKeys.length === 0}
              onClick={openTeammatesModal}>
              <FormattedMessage id={'order.table.button.assignToTeammate'} />
            </Button>
          ) : null
      }}
      loading={isLoading}
      locale={{
        triggerAsc: intl.formatMessage({ id: 'order.table.sortAsc' }),
        triggerDesc: intl.formatMessage({ id: 'order.table.sortDesc' }),
        cancelSort: intl.formatMessage({ id: 'order.table.cancelSort' }),
        emptyText: (
          <Empty
            description={intl.formatMessage({ id: 'order.table.emptyText' })}
          />
        )
      }}
    />
  )
}
OrdersTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  openTeammatesModal: PropTypes.func,
  rowSelection: PropTypes.object
}
export default injectIntl(OrdersTable)
